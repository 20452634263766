import { Typography } from '@mui/material'
import React from 'react'
import "./Footer.css"
import { Link } from 'react-router-dom'
import {BsGithub,BsInstagram,BsLinkedin} from "react-icons/bs"

const Footer = () => {
  return <div className='footer'>
  <div>
    <Typography variant='h5'>About Me</Typography>
    <Typography >Hey, my name is Anish Kumar. I am a Full-Stack Developer.
    </Typography>
    <Link to="/contact" className="footerContactBtn">
          <Typography>Contact Me</Typography>
    </Link>
  </div>
  <div>
    <Typography variant='h6'>Social Media</Typography>
    <a href='https://github.com/ianishm15/' target="black">
      <BsGithub/>
    </a>
    <a href='https://instagram.com/imanishm/' target="black">
      <BsInstagram/>
    </a>
    <a href='https://www.linkedin.com/in/anishm15/' target="black">
      <BsLinkedin/>
    </a>
  </div>
</div>
}

export default Footer