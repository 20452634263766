import { Typography } from '@mui/material'
import React from 'react'
import "./About.css"
import anishKimg from "../../Images/anishKimg.jpg"

const About = ({about}) => {
  return <div>
    <div className="about">
        <div className="aboutContainer">
            <Typography>{about.quote}</Typography>
        </div>
        <div className="aboutContainer2">
            <div>
                <img src={anishKimg} alt='ash' className='aboutAvatar'/>

                <Typography variant='h4'
                style={{margin:"1vmax 0",color:"green"}}
                >{about.name}</Typography>
                <Typography>{about.title}</Typography>
                
            </div>
            <div>
            <Typography 
            style={{
                wordSpacing:"5px",
                lineHeight:"50px",
                letterSpacing:"5px",
                textAlign:"right"
            }}>{about.description}</Typography>
        </div>
        </div>
        
    </div>

  </div>
  
}

export default About